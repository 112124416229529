import { Controller } from "@hotwired/stimulus"

export default class ShowcaseSidebarController extends Controller {
  static targets = ["toggler"]

  connect () {
    this.element.addEventListener("turbo:before-fetch-request", (event) => {
      if (this.hasTogglerTarget) {
        const sidebarExpanded = this.togglerTarget.ariaExpanded

        if (sidebarExpanded) {
          event.detail.fetchOptions.headers['X-Sidebar-Expanded'] = sidebarExpanded
        }
      }
    })
  }
}
