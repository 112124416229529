import { Controller } from "@hotwired/stimulus"

export default class RedirectionFormController extends Controller {
  static values = {
    timeout:  { type: Number, default: 1000 }
  }

  connect() {
    setTimeout(()=> {
      this.element.submit()
    }, this.timeoutValue)
  }
}
