import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

import Bounds from "./javascript/bounds"
import Markers from "./javascript/markers"

export default class MapController extends Controller {
  static targets = ["leaflet", "marker"]
  static values = {
    embedded: { type: Boolean, default: false }
  }

  connect () {
    this.createMap()

    this.bounds   = new Bounds(this)
    this.markers  = new Markers(this)

    this.markers.addMarkers(this.markerTargets)
    this.bounds.reset()
  }

  disconnect () {
    this.map.remove();
  }

  createMap () {
    this.map = L.map(this.leafletTarget, {
      zoomControl:        false,
      minZoom:            6,
      maxZoom:            19,
      doubleClickZoom:    true,
      attributionControl: false
    })

    L.tileLayer(
      "https://data.geopf.fr/wmts?service=wmts&request=GetTile&version=1.0.0&layer=HR.ORTHOIMAGERY.ORTHOPHOTOS&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&format=image/jpeg&style=normal", {
      minZoom:     0,
      maxZoom:     19,
      tileSize:    256,
      attribution: 'IGN-F/Géoportail'
    }).addTo(this.map)
  }

  expand () {
    this.element.classList.remove("map--embedded")
    this.map.invalidateSize()
  }

  collapse () {
    this.element.classList.add("map--embedded")
    this.map.invalidateSize()
  }

  zoomIn () {
    this.map.zoomIn()
  }

  zoomOut () {
    this.map.zoomOut()
  }
}
