import { Controller } from "@hotwired/stimulus"

export default class SwitchFormController extends Controller {
  static targets = ["item"]

  update (event) {
    const currentValue = event.target.value + ""

    this.itemTargets.forEach((item) => {
      let expectedValues
      let enabled

      if (item.dataset.switchFormValue !== undefined) {
        expectedValues = [item.dataset.switchFormValue + ""]
      } else if (item.dataset.switchFormValues !== undefined) {
        expectedValues = JSON.parse(item.dataset.switchFormValues)
      } else {
        return
      }

      enabled = expectedValues.includes(currentValue)

      item.hidden = !enabled
      item.disabled = !enabled
    })
  }
}
