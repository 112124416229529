import { Controller } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"

export default class extends Autocomplete {
  static values = {
    allowSubmitWithoutSelection: Boolean
  }

  connect () {
    super.connect()
    this.element.classList.remove("hidden")
    this.element.addEventListener("input", this.unselectFirstOption.bind(this))
    this.element.addEventListener("loadend", this.selectFirstOption.bind(this))
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.onKeyDown.bind(this))
    }
  }

  selectFirstOption () {
    const item = this.options[0]

    if (item) this.select(item)
  }

  unselectFirstOption () {
    const previouslySelected = this.selectedOption

    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected")
      previouslySelected.classList.remove(...this.selectedClassesOrDefault)
    }
  }

  onKeyDown (event) {
    if (event.key != "Enter") {
      return
    }

    if (!this.selectedOption && this.hasAllowSubmitWithoutSelectionValue && this.inputTarget.value) {
      this.submitValue(this.inputTarget.value)
      event.preventDefault()
    }
  }

  optionsForFetch () {
    return {
      headers: {
        "Accept-Variant": "Autocomplete",
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  }

  submitValue (value) {
    let element = document.createElement("div");
    element.innerHTML =  value

    this.commit(element)
  }
}
