import { Controller } from "@hotwired/stimulus"

export default class SwitchAttributeController extends Controller {
  static targets = ["item"]
  static values  = {
    sequence: { type: Array },
    index:    { type: Number, default: 0 }
  }

  update (event) {
    this.indexValue += 1
    if (this.indexValue > (this.sequenceValue.length - 1)) this.indexValue = 0

    const attributes = this.sequenceValue[this.indexValue]

    for (let [attribute, value] of Object.entries(attributes)) {
      this.itemTargets.forEach((item) => {
        item.setAttribute(attribute, value)
      })
    }
  }
}
