import { Controller } from "@hotwired/stimulus"

export default class ToggleExpandController extends Controller {
  static targets = ["hidden", "toggle", "disabled"]
  static values  = {
    revertDelay: { type: Number }
  }

  toggle(event) {
    if (this.revertTimeoutId) clearTimeout(this.revertTimeoutId)

    const button = event.currentTarget

    if (button.ariaExpanded == "true") {
      button.setAttribute("aria-expanded", "false")
      this.hiddenTargets.forEach((target) => target.toggleAttribute("hidden", true))
      this.toggleTargets.forEach((target) => target.toggleAttribute("hidden"))
      this.disabledTargets.forEach((target) => target.toggleAttribute("disabled", true))
    } else {
      button.setAttribute("aria-expanded", "true")
      this.hiddenTargets.forEach((target) => target.toggleAttribute("hidden", false))
      this.toggleTargets.forEach((target) => target.toggleAttribute("hidden"))
      this.disabledTargets.forEach((target) => target.toggleAttribute("disabled", false))
    }

    // If a previous timeout exist, just delete it
    // Otherwise, if a delay is provided, enqueues a revert call
    if (this.revertTimeoutId) {
      this.revertTimeoutId = null
    } else if (this.revertDelayValue) {
      this.revertTimeoutId = setTimeout(
        this.toggle.bind(this, { currentTarget: button }),
        this.revertDelayValue
      )
    }
  }
}
