import { Controller } from "@hotwired/stimulus"

export default class MultipleAutocompleteController extends Controller {
  static targets = ["template", "target", "autocomplete"]

  connect () {
    this._resizeInput(this.autocompleteTarget.firstElementChild)
  }

  remove (event) {
    const value = event.target.value
    const label = event.target.nextElementSibling.innerText

    this.element.dispatchEvent(
      new CustomEvent("multiple-autocomplete.remove", {
        bubbles: true,
        detail: { value: value, label: label }
      })
    )
  }

  add (event) {
    const id = event.detail.value
    const label = event.detail.textValue

    this._cloneTemplate(id, label)
    this._clearAutocompleteSearch()

    this.element.dispatchEvent(
      new CustomEvent("multiple-autocomplete.add", {
        bubbles: true,
        detail: { value: id, label: label }
      })
    )
  }

  resize (event) {
    this._resizeInput(event.target)
  }

  _resizeInput(input) {
    const size = Math.max(input.value.length, input.getAttribute("placeholder")?.length || 0)

    input.size = size + 2
  }

  _clearAutocompleteSearch() {
    event.target.parentNode.querySelector("input[type=search]").value = null
  }

  _cloneTemplate (id, label) {
    let content = this.templateTarget.innerHTML.replace(/LABEL/g, label)

    content = content.replace(/ID/g, id)

    if (this.element.querySelector(`input[type='checkbox'][value='${id}']`)) return

    this.targetTarget.insertAdjacentHTML("beforebegin", content)
  }
}
