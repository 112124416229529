import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class DirectUploadFieldController extends Controller {
  static targets = ["input"];

  initialize () {
    this.onChangeBound = this.onChange.bind(this)
  }

  connect() {
    if (this.hasListTarget) {
      this.listElement = this.listTarget
    } else {
      this.listElement = this.createList()
    }

    this.element.addEventListener('change', this.onChangeBound)
  }

  disconnect() {
    this.element.removeEventListener("change", this.onChangeBound)
  }

  createList () {
    const list = document.createElement('ul')

    list.setAttribute("class", "direct-upload__list")
    list.setAttribute("data-direct-upload-field-target", "list")
    this.element.parentNode.insertBefore(list, this.element.nextSibling)

    return list
  }

  onChange (event) {
    Array.from(this.element.files).forEach((file) => {
      new DirectUploadController(this.element, this.listElement, file).start()
    })

    // Clear the selected files from the input
    this.element.value = null
  }
}

class DirectUploadController {
  constructor(input, list, file) {
    this.input = input
    this.list = list
    this.file = file
    this.directUpload = new DirectUpload(file, this.url, this)
  }

  get url() {
    return this.input.getAttribute("data-direct-upload-url")
  }

  start () {
    const listItem = document.createElement('li')
    const filename = document.createElement('div')
    const itemHint = document.createElement('div')
    const progress = document.createElement('div')

    if (!this.input.hasAttribute("multiple")) {
      this.list.replaceChildren()
    }

    listItem.setAttribute("class", "direct-upload__item")
    filename.setAttribute("class", "direct-upload__filename")
    itemHint.setAttribute("class", "direct-upload__hint")
    progress.setAttribute("class", "direct-upload__progress direct-upload__progress--indeterminate")

    filename.textContent = this.file.name
    itemHint.textContent = "Le fichier est en cours de téléchargement, veuillez patienter."

    listItem.appendChild(filename)
    listItem.appendChild(itemHint)
    listItem.appendChild(progress)
    this.list.appendChild(listItem)

    this.progress = progress

    this.directUpload.create((error, blob) => {
      if (error) {
        listItem.removeChild(itemHint)
        listItem.classList.add("direct-upload__item--failed")
        progress.classList.add("direct-upload__progress--failed")

        const error = document.createElement('div')
        error.setAttribute("class", "direct-upload__error")
        error.textContent = "Une erreur s'est produite."
        filename.insertAdjacentElement("afterend", error)

      } else {
        listItem.removeChild(itemHint)
        listItem.classList.add("direct-upload__item--complete")
        progress.classList.add("direct-upload__progress--complete")

        const hiddenInput = document.createElement("input")
        hiddenInput.type = "hidden"
        hiddenInput.name = this.input.name
        hiddenInput.value = blob.signed_id
        this.input.insertAdjacentElement("beforebegin", hiddenInput)
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) => {
      const progress = event.loaded / event.total * 100

      if (progress > 0) {
        this.progress.classList.remove("direct-upload__progress--indeterminate")
      }

      this.progress.style.width = `${progress}%`
    })
  }
}
