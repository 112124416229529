import L from "leaflet"

export default class Markers {
  constructor(controller) {
    this.controller = controller
    this.map        = controller.map
    this.markers    = []
  }

  addMarkers (markers) {
    markers.forEach((marker) => this.addMarker(marker))
  }

  addMarker (marker) {
    const coordinates = JSON.parse(marker.dataset.coordinates)
    const options     = {
      html:       marker.innerHTML,
      className:  marker.getAttribute("class")
    }

    if (marker.dataset.size) {
      options["iconSize"] = JSON.parse(marker.dataset.size)
    } else  {
      const svg    = marker.getElementsByTagName("svg")[0]
      const width  = parseInt(svg.getAttribute("width"))
      const height = parseInt(svg.getAttribute("height"))

      options["iconSize"] = [width, height]
    }

    if (marker.dataset.anchor) {
      options["iconAnchor"] = JSON.parse(marker.dataset.anchor)
    }

    this.add(coordinates, options)
  }

  add (coordinates, options) {
    const latlng = L.latLng(coordinates)
    if (!latlng || latlng === undefined) return

    const icon   = L.divIcon(options)
    const marker = L.marker(latlng, { icon: icon })

    this.markers.push(marker)
    marker.addTo(this.map)
  }

  forEach (callback) {
    this.markers.forEach(callback)
  }

  get toArray () {
    return this.markers
  }

  get length () {
    return this.markers.length
  }

}
