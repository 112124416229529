import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class ModalController extends Controller {
  static targets = ["content"]
  static values = { closePath: String }

  connect () {
    useTransition(this)

    if (!this.element.hasAttribute("hidden")) this.enter()
  }

  keydown (event) {
    if (event.key == "Escape") this.close(event)
  }

  async close (event) {
    if (event.type == "click") {
      event.preventDefault()
    }

    const closeEvent = this.dispatch("close")
    if (closeEvent.defaultPrevented) return

    await this.leave()

    if (this.hasClosePathValue && this.closePathValue.length) {
      Turbo.visit(this.closePathValue, { action: "replace" })
    }

    this.element.remove()
  }
}
