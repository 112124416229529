import { Controller } from "@hotwired/stimulus"

export default class FormSubmitController extends Controller {
  connect () {
    this._hideSubmitButton()
  }

  submit () {
    this.element.requestSubmit()
  }

  _hideSubmitButton () {
    this.element.querySelector("button[type=submit]").toggleAttribute("hidden", true)
  }
}
