import { StreamActions } from "@hotwired/turbo"
import { Turbo } from "@hotwired/turbo-rails"

StreamActions.refresh_or_redirect = function () {
  const src = this.getAttribute("src")

  if (src === window.location.href) {
    Turbo.session.refresh(document.baseURI)
  } else {
    Turbo.visit(src, { action: "advance" })
  }
}

StreamActions.refresh_or_replace = function () {
  const src = this.getAttribute("src")

  if (src === window.location.href) {
    Turbo.session.refresh(document.baseURI)
  } else {
    Turbo.visit(src, { action: "replace" })
  }
}
