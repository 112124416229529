import L from "leaflet"

export default class Bounds {
  static maxZoom = 18

  constructor(controller) {
    this.controller = controller
    this.map        = controller.map
  }

  reset () {
    this.resetBoundsOnMarkers()
  }

  resetBoundsOnMarkers () {
    const markers = this.controller.markers.toArray
    const length  = markers.length
    if (!length) return

    if (length === 1) {
      this.setView(markers[0].getLatLng())
    } else {
      const bounds = L.latLngBounds()

      markers.forEach((marker) =>  {
        const point = marker.getLatLng()
        bounds.extend(point)
      })

      this.setBounds(bounds)
    }
  }

  setView (latLng, zoom) {
    if (!zoom) zoom = Bounds.maxZoom

    this.map.setView(latLng, zoom)
  }

  setBounds (bounds) {
    if (!bounds.isValid()) return

    this.map.fitBounds(bounds, {
      maxZoom: Bounds.maxZoom
    })
  }
}